/* Style the container of the suggestions */
.pac-container {
  @apply w-full xs:w-96 text-white shadow-lg rounded-lg mt-14 dark:bg-gray-800 bg-white;
}

.pac-container {
  z-index: 99999999999 !important;
}
/* Style each suggestion */
.pac-item {
  @apply w-full text-gray-800 hover:bg-gray-100 p-4 border-0 dark:text-white dark:hover:bg-gray-700 !important;
}

/* Style the query within each suggestion */
.pac-item-query {
  @apply font-body font-bold text-sm dark:text-gray-300;
}

/* Highlighted text within suggestions */
.pac-matched {
  @apply text-green-500 dark:text-green-300;
}
